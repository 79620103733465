<template>
  <div>
    <!-- Label and Color Preview-->
    <v-sheet
        v-if="!onlyColors"
        @click="select"
        color="transparent"
        class="pointer d-flex justify-space-between align-center  py-2">
      <h5 :style="`color : ${labelColor || wsDARKER}`" :class="[{'font-weight-regular' : fontRegular}]">{{ $t(label) }}</h5>
      <v-sheet
          style="border-radius: 50%"
          :style="`border : ${element === expandElement ? 2 : 1}px solid ${wsACCENT}`"
          height="22" width="22"
          :color="GET_AVALON_COLOR(color)">
        <span></span>
      </v-sheet>
    </v-sheet>
    <v-divider
        v-if="divider"
        :style="`border-color : ${wsDARKLIGHT};`"
        class="my-1"
    />
    <!-- COLOR SELECT EXPAND -->
    <v-expand-transition>
      <div
          v-if="expand || onlyColors"
          :class="[{'pt-3' : !onlyColors}]">

        <v-sheet
            :color="!onlyColors ? wsLIGHTCARD : 'transparent'"
            :class="[{'pa-3' : !onlyColors}]"
            style="border-radius: 8px"
        >
          <!-- Action title -->
          <h4 v-if="!onlyColors" :style="`color : ${wsDARKER}`">
            {{ $t('avalon.color_styles.choose_another_color') }}
          </h4>
          <!-- Color Palette picker -->
          <v-sheet
              :class="[{'mt-3' : !onlyColors}]"
              class="mt-3 mb-3"
              style="border-radius: 8px"
              color="transparent"   >


            <div class="d-flex">
              <v-hover
                  v-for="( configColor , index ) in AVALON_COLORS_LIST"
                  :key="configColor" #default="{hover}"
              >
                <v-sheet

                    @click="color = returnColor ? GET_AVALON_COLOR(configColor) : configColor"
                    :color="GET_AVALON_COLOR(configColor)"
                    :class="[
                        {roundedTopLeft : index === 0} ,
                        {roundedBottomLeft : index === 0} ,
                        {roundedTopRight : index === 5},
                        {roundedBottomRight : index === 5}
                      ]"
                    class="pointer"
                    width="100%"
                    height="32"
                >
                  <div
                      :style="hover || configColor === color ? `border : 2px solid ${wsDARKLIGHT};` : null "
                      :class="[
                        {roundedTopLeft : index === 0} ,
                        {roundedBottomLeft : index === 0} ,
                        {roundedTopRight : index === 5},
                        {roundedBottomRight : index === 5}
                      ]"
                      style="width: 100%; height: 100%">
                  </div>
                </v-sheet>
              </v-hover>
            </div>

          </v-sheet>

          <!-- Hint -->
          <h5 @click="expandColorPicker = !expandColorPicker"
              :style="`color : ${wsDARKER}`"
              class="pointer ml-n2">
            <v-icon :color="wsDARKER">mdi-menu-{{ !expandColorPicker ?  'right' : 'down' }}</v-icon>
            {{ $t('ChooseColor' ) }}
          </h5>

          <v-expand-transition>
            <div      v-if="expandColorPicker">
              <ws-text-field
                  @change="color =  $event"
                  :value="GET_AVALON_COLOR(color)"
                  class="mt-4"
                  background-color="white"
              />

              <v-color-picker
                  class="mt-3"
                  @input="changeSliderColor"
                  :value="GET_AVALON_COLOR(color)"
                  hide-inputs
                  hide-mode-switch
                  style="background-color: transparent"
              />
            </div>


          </v-expand-transition>


        </v-sheet>
      </div>
    </v-expand-transition>

  </div>
</template>

<script>
export default {
  name: "colorParamPicker",
  props : {
    value : {
      type : String
    },
    label : {
      type : String,
      default : "Param"
    },
    divider : {
      type : Boolean,
      default : false
    },
    labelColor : {
      type : String
    },
    fontRegular : {
      type : Boolean,
      default : false
    },
    expandedElement : {
      type : String
    },
    returnColor : {
      type : Boolean,
      default : false
    },
    onlyColors : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      expandElement : false,
      color : '#FFFFFF',
      expand : false,
      expandColorPicker : false,
      timeOutId : null,
      uuid : null
    }
  },
  watch : {
    color() {
      if ( this.color !== this.value ) {
        this.$emit('input' , this.color)
      }
    },
    value() {
      if ( this.color !== this.value ) {
        this.color = this.value
      }
    },
    expandedElement(value ) {
      if ( value !== this.uuid ) {
        this.expand = false
      }
    },
  },
  methods : {
    changeSliderColor(color) {
      if ( this.timeOutId ) {
        clearTimeout(this.timeOutId)
      }
      this.timeOutId = setTimeout(() => {
        this.changeColor(color)
      },200)
    },
    changeColor(color ) {
      this.color = color
    },
    select() {
      this.expand = !this.expand
      this.$emit('expand' , this.expand ? this.uuid : null)
    }
  },
  beforeMount() {
    this.uuid = this.$uuid.v4()
    if ( this.value ) {
      this.color = this.value
    }
  }
}
</script>

<style scoped>
.roundedTopLeft  {
  border-top-left-radius : 6px;
}
.roundedBottomLeft  {
  border-bottom-left-radius : 6px;
}
.roundedTopRight  {
  border-top-right-radius : 6px;
}
.roundedBottomRight  {
  border-bottom-right-radius : 6px;
}
</style>